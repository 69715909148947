import React, { useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"
//@ts-ignore
import ImgPat from "../../images/features/inbox/pat.jpg"
//@ts-ignore
import ImgJanet from "../../images/features/inbox/janet.jpg"
import { SEO } from "../../utils/SEO"

const ContainerAnimation = keyframes`
  0% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }

  25% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(7deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  75% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-10deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  100% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
`

const ScriptAnimation = keyframes`
  0% {
    transform: rotateY(0) perspective(700px) scale(1) translate(0, 0);
  }
  100% {
    transform: rotateY(-20deg) perspective(700px) scale(0.85) translate(150rem, 0px);
  }
`

const Container = styled.div`
  section.hero {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 120rem 0 200rem;
    flex-direction: row-reverse;

    ${media("<tablet")} {
      padding-bottom: 80rem;
    }

    ${media(">phone", "<desktop")} {
      flex-direction: column-reverse;
      gap: 110rem;

      > .left {
        text-align: center;
        align-items: center;
      }
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pre-header {
        display: flex;
        align-items: center;
        gap: 10rem;
        font-size: 14rem;
        color: ${({ theme }) => theme.text.lighter};
        font-family: "Gilroy-Regular", sans-serif;
        margin-bottom: 10rem;
      }

      h1 {
        font-size: 55rem;
        font-family: "Gilroy-Bold", sans-serif;
        color: ${({ theme }) => theme.text.dark};
        font-weight: unset;
        margin: 0;

        span {
          background: -webkit-linear-gradient(#60a5fa, #818cf8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      h2 {
        font-weight: normal;
        font-size: 20rem;
        color: rgb(76, 87, 115);
        line-height: 35rem;
        max-width: 490rem;
      }

      ${media("<phone")} {
        > h1:not(.desktop) {
          font-size: 35rem;
        }

        > h2:not(.desktop) {
          font-size: 20rem;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 30rem;
      position: relative;
      perspective: 2000rem;
      perspective-origin: 50% 50%;
      overflow: visible;

      ${media("<tablet")} {
        display: none;
      }

      .back {
        position: absolute;
        top: 30rem;
        bottom: 30rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 200rem;
        height: 200rem;
        opacity: 0;
        background: #bfdbfe;
        border-radius: 90%;
        transform-origin: center;
      }

      .anim-container {
        display: flex;
        perspective: 1500rem;
        overflow: visible;
        width: 100%;
        justify-content: center;
        transform-origin: center;

        > div {
          display: flex;
          flex-direction: column;
          max-width: 600rem;

          > div {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: row-reverse;
            margin-bottom: 2rem;

            &.gutter {
              margin-bottom: 10rem;
            }

            &.inbound {
              flex-direction: row;

              .bubble {
                background-color: ${({ theme }) => theme.surfaces.darker};
                border-color: ${({ theme }) => theme.surfaces.darker};
                border-radius: 20rem 5rem 5rem 20rem;
                color: #334155;
              }
            }

            .bubble {
              text-rendering: optimizeLegibility;
              padding: 10rem 15rem;
              border-radius: 5rem 10rem 10rem 5rem;

              transition: all ease-in-out 250ms;
              background: ${({ theme }) => theme.primary};
              border: 1px solid ${({ theme }) => theme.primary};
              color: #fff;
              font-size: 16rem;
              line-height: 24rem;
              font-family: "Inter", sans-serif;

              width: fit-content;
              max-width: calc(80% - 20px);

              box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

              &.internal {
                background: #fcd34d;
                border-color: #fcd34d;
                color: #b45309;
              }
            }

            .avatar {
              background-color: #FBCFE8;
              color: #BE185D;
              border-radius: 90%;
              height: 30rem;
              width: 30rem;
              font-size: 10rem;
              margin: 0 10rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-position: center;
              background-size: cover;

              &.pat {
                background-image: url("${ImgPat}");
              }

              &.janet {
                background-image: url("${ImgJanet}");
              }

              &.hidden {
                opacity: 0;
              }

            }
          }
        }
      }
    }
  }
}

section.quick-feats {
  display: flex;
  align-items: center;
  margin: 0 -20rem;
  padding: 70rem 20rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

  > div {
    display: flex;
    gap: 90rem;

    ${media("<desktop")} {
      > div {
        min-width: calc(50% - 40rem);
      }

      flex-wrap: wrap;
      gap: 50rem;
    }

    ${media("<=phone")} {
      flex-direction: column;

      > div:nth-of-type(even) {
        min-width: unset;
        align-items: flex-end;
        text-align: right;
      }
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 15rem;

      svg {
        width: 50rem;
        height: 50rem;
      }

      .title {
        font-size: 20rem;
        color: ${({ theme }) => theme.text.dark};
        font-family: "Gilroy-SemiBold", sans-serif;
      }

      .description {
        color: ${({ theme }) => theme.text.normal};
        font-size: 15rem;
        line-height: 30rem;
      }
    }
  }
}

section.start-right {
  margin: 0 -20rem;
  padding: 100rem 20rem 100rem;
  border-top: 1px solid ${({ theme }) => theme.borders.light};

  .leader {
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      margin: auto;
      gap: 20rem;
      text-align: center;

      .pretitle {
        color: ${({ theme }) => theme.primary};
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 20rem;
      }

      h3.title {
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 50rem;
        color: ${({ theme }) => theme.text.dark};
      }

      .subtitle {
        font-size: 18rem;
        line-height: 30rem;
        color: ${({ theme }) => theme.text.normal};
        max-width: 400rem;
      }
    }
  }

  .content {
    border-radius: 10rem;
    padding: 30rem 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .tile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50rem 0;
      max-width: 1000rem;
      gap: 20rem;

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }

      .left {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .icon {
          width: 30rem;
          height: 30rem;
          background: #eff6ff;
          border-radius: 90%;

          svg {
            width: 30rem;
            height: 30rem;
            transform: scale(0.95);
          }
        }

        h4 {
          font-family: "Gilroy-Black", sans-serif;
          font-size: 30rem;
          color: ${({ theme }) => theme.primary};
          margin: 0 0 10rem;
          background: -webkit-linear-gradient(#60a5fa, #818cf8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        h5 {
          margin: 10rem 0;
          font-size: 16rem;
          letter-spacing: 2rem;
          font-weight: 600;
          color: #60a5fa;
          text-transform: uppercase;
        }

        p {
          font-size: 16rem;
          line-height: 28rem;
          margin: 0;
          color: ${({ theme }) => theme.text.light};
        }
      }

      > .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
          position: relative;
          z-index: 50;
          border-radius: 15rem;
          max-width: 300rem;

          &.shadow {
            box-shadow: #eff6ff 0px 50px 100px -20px,
            #c7d2fe 0px 30px 60px -30px;
          }
        }
      }

      ${media("<desktop")} {
        &:not(.ms) {
          flex-direction: column;
          text-align: center;
          align-items: center;
          gap: 50px;

          .left {
            max-width: 500rem;
            align-items: center;
          }
        }
      }
    }
  }
}
`

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const FeaturesTeamSms = ({ data, location }) => {
    const isMounted = useRef(true)
    const animationFrameRef = useRef<number>()
    const animContainerRef = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<{ x: number; y: number }>({ x: 2, y: 15 })

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const newX = Math.cos((valuesRef.current.x += 0.005)) * 2
            const newY = Math.cos((valuesRef.current.y += 0.003)) * 4

            // animContainerRef.current.style.transform = `rotateX(${newX}deg) rotateY(${newY}deg)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        if (animationFrameRef.current !== 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <SEO
                title={"Team SMS with 23shout Conversations"}
                description={"A shared inbox for SMS that your team will love"}
                ogImage={
                    "https://23shout.com/social/og/og-feature-team-sms.png"
                }
            />
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <div className={"pre-header"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        x="0"
                                    />
                                </g>
                                <g>
                                    <g>
                                        <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                    </g>
                                </g>
                            </svg>
                            SMS are included free with 23shout
                        </div>
                        <h1>
                            A <span>shared</span> inbox for your{" "}
                            <span>team</span> to your <span>customers</span>.
                        </h1>
                        <h2>
                            Start conversations with your clients for free
                            through SMS without switching apps.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div
                            className={"anim-container"}
                            ref={animContainerRef}
                        >
                            <div>
                                <div className={"inbound gutter"}>
                                    <div className={"bubble"}>
                                        Hey, I just heard apparently 23 has a
                                        team inbox for SMS now?
                                    </div>
                                    <div className={"avatar"}>JT</div>
                                </div>
                                <div className={"outbound gutter"}>
                                    <div className={"bubble"}>
                                        That's right, I'm using it now! 😁
                                    </div>
                                    <div className={"avatar pat"}></div>
                                </div>
                                <div className={"inbound"}>
                                    <div className={"bubble"}>Cool! 😎</div>
                                    <div className={"avatar hidden"}>JT</div>
                                </div>
                                <div className={"inbound gutter"}>
                                    <div className={"bubble"}>
                                        But, what can it do? 🤔
                                    </div>
                                    <div className={"avatar"}>JT</div>
                                </div>
                                <div className={"outbound gutter"}>
                                    <div className={"bubble"}>
                                        Conversations in 23shout provide a way
                                        for the team to collaborate on inbound
                                        and outbound messaging, start group
                                        chats in SMS, field incoming messages,
                                        and assign conversations to agents all
                                        in real time!
                                    </div>
                                    <div className={"avatar pat"}></div>
                                </div>
                                <div className={"inbound gutter"}>
                                    <div className={"bubble"}>
                                        Woah, that's a lot...
                                    </div>
                                    <div className={"avatar"}>JT</div>
                                </div>
                                <div className={"outbound gutter"}>
                                    <div className={"bubble internal"}>
                                        Hey, don't forget the message templates
                                        for quick replies and internal notes
                                        too!
                                    </div>
                                    <div className={"avatar janet"}></div>
                                </div>
                                <div className={"outbound"}>
                                    <div className={"bubble internal"}>
                                        Oh right, thanks! 😬
                                    </div>
                                    <div className={"avatar hidden"}></div>
                                </div>
                                <div className={"outbound gutter"}>
                                    <div className={"bubble"}>
                                        We've also got an internal note system
                                        for private messages and coaching.
                                    </div>
                                    <div className={"avatar pat"}></div>
                                </div>
                                <div className={"inbound"}>
                                    <div className={"bubble"}>
                                        That's it... I'm signing up. 😐
                                    </div>
                                    <div className={"avatar"}>JT</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <path d="M7,4c0-1.11-0.89-2-2-2S3,2.89,3,4s0.89,2,2,2S7,5.11,7,4z M10.19,4.5L10.19,4.5c-0.41,0-0.76,0.25-0.92,0.63 C8.83,6.23,7.76,7,6.5,7h-3C2.67,7,2,7.67,2,8.5V11h6V8.74c1.43-0.45,2.58-1.53,3.12-2.91C11.38,5.19,10.88,4.5,10.19,4.5z M19,17 c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S17.89,17,19,17z M20.5,18h-3c-1.26,0-2.33-0.77-2.77-1.87 c-0.15-0.38-0.51-0.63-0.92-0.63h0c-0.69,0-1.19,0.69-0.94,1.33c0.55,1.38,1.69,2.46,3.12,2.91V22h6v-2.5C22,18.67,21.33,18,20.5,18 z M17.25,11.09c0,0,0-0.01,0.01,0c-1.06,0.27-1.9,1.11-2.17,2.17c0,0,0-0.01,0-0.01C14.98,13.68,14.58,14,14.11,14 c-0.55,0-1-0.45-1-1c0-0.05,0.02-0.14,0.02-0.14c0.43-1.85,1.89-3.31,3.75-3.73c0.04,0,0.08-0.01,0.12-0.01c0.55,0,1,0.45,1,1 C18,10.58,17.68,10.98,17.25,11.09z M18,6.06c0,0.51-0.37,0.92-0.86,0.99c0,0,0,0,0,0c-3.19,0.39-5.7,2.91-6.09,6.1c0,0,0,0,0,0 C10.98,13.63,10.56,14,10.06,14c-0.55,0-1-0.45-1-1c0-0.02,0-0.04,0-0.06c0-0.01,0-0.02,0-0.03c0.5-4.12,3.79-7.38,7.92-7.85 c0,0,0.01,0,0.01,0C17.55,5.06,18,5.51,18,6.06z" />
                            </svg>
                            <div className={"title"}>
                                Collaborate seamlessly
                            </div>
                            <div className={"description"}>
                                As conversations start you can assign them out
                                to your team mates, and search across all
                                messages anytime.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35c-.19-.19-.51-.19-.7 0zM16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16z" />
                            </svg>
                            <div className={"title"}>Inbound and outbound</div>
                            <div className={"description"}>
                                Use your phone numbers to receive messages and
                                start conversations with your contacts.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <path d="M14,11c0,0.55-0.45,1-1,1H4c-0.55,0-1-0.45-1-1s0.45-1,1-1h9C13.55,10,14,10.45,14,11z M3,7c0,0.55,0.45,1,1,1h9 c0.55,0,1-0.45,1-1s-0.45-1-1-1H4C3.45,6,3,6.45,3,7z M10,15c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1s0.45,1,1,1h5 C9.55,16,10,15.55,10,15z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71 L18.01,12.87z M17.3,13.58l-5.16,5.16C12.05,18.83,12,18.95,12,19.09v1.41c0,0.28,0.22,0.5,0.5,0.5h1.41c0.13,0,0.26-0.05,0.35-0.15 l5.16-5.16L17.3,13.58z" />
                            </svg>
                            <div className={"title"}>Attach internal notes</div>
                            <div className={"description"}>
                                Leave a private note for the team, and coach
                                conversations in realtime.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        y="0"
                                    />
                                </g>
                                <g>
                                    <path d="M20,2H4C2.9,2,2,2.9,2,4v15.59c0,0.89,1.08,1.34,1.71,0.71L6,18h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M13.57,11.57 l-1.12,2.44c-0.18,0.39-0.73,0.39-0.91,0l-1.12-2.44l-2.44-1.12c-0.39-0.18-0.39-0.73,0-0.91l2.44-1.12l1.12-2.44 c0.18-0.39,0.73-0.39,0.91,0l1.12,2.44l2.44,1.12c0.39,0.18,0.39,0.73,0,0.91L13.57,11.57z" />
                                </g>
                            </svg>
                            <div className={"title"}>Templates and macros</div>
                            <div className={"description"}>
                                Save time by setting up commonly used messages
                                to insert with a single click, available to the
                                whole team.
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"start-right"}>
                    <div className={"pageConstrained"}>
                        <div className={"leader"}>
                            <div className={"title"}>
                                <div className={"pretitle"}>
                                    Centralised messaging
                                </div>
                                <h3 className={"title"}>
                                    Cross Platform; Direct Messaging
                                </h3>
                                <div className={"subtitle"}>
                                    See message logs directly inside contacts
                                    profiles, alongside all their important
                                    information.
                                </div>
                            </div>
                        </div>

                        <div className={"content"}>
                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Modern messaging</h5>
                                    <h4>
                                        A familiar and clean interface your team
                                        will love
                                    </h4>
                                    <p>
                                        We're not running Windows 95, 23shout is
                                        designed to give you a convenient and
                                        pleasant experience.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/inbox.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Divide and conquer</h5>
                                    <h4>
                                        Assign team members to handle incoming
                                        conversations
                                    </h4>
                                    <p style={{ marginBottom: "10rem" }}>
                                        When an inbound conversation starts it
                                        will alert anyone with access to
                                        messages, their person to reply will be
                                        assigned to the conversation and
                                        continue handling until it's completed.
                                    </p>
                                    <p>
                                        Star important conversations and move
                                        them to the top of the list, and have
                                        messages automatically assigned to
                                        account owners.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/assign.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Smarts to keep up</h5>
                                    <h4>
                                        Achieve more with built in artificial
                                        intelligence modelling
                                    </h4>
                                    <p>
                                        Our exclusive natural language models
                                        unlock the ability to find messages
                                        based on an understanding of language.
                                        No more lost messages, just type
                                        something like what you're looking for!
                                    </p>
                                    <p style={{ marginTop: "10rem" }}>
                                        Searching for <em>I'm on the phone</em>{" "}
                                        will match <em>Was on a call!</em> for
                                        example.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/abstract.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"leader"} style={{ marginTop: "100px" }}>
                        <div className={"title"}>
                            <div className={"pretitle"}>
                                More ways we're different
                            </div>
                        </div>
                    </div>
                    <section className={"quick-feats"}>
                        <div className={"pageConstrained"}>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                            x="0"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                        </g>
                                    </g>
                                </svg>
                                <div className={"title"}>Beautiful calling</div>
                                <div className={"description"}>
                                    We're not that solution that runs on Windows
                                    95, we're designed from the ground up to
                                    fill the void for a modern contact centre.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <rect fill="none" height="24" width="24" />
                                    <g>
                                        <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15c0.11-0.26,0.15-0.56,0.09-0.87C17.84,2.49,17.14,1.99,16.4,2 c-1.59,0.03-3,0.81-3.9,2l-5,0C4.46,4,2,6.46,2,9.5c0,2.25,1.37,7.48,2.08,10.04C4.32,20.4,5.11,21,6.01,21L8,21c1.1,0,2-0.9,2-2v0 h2v0c0,1.1,0.9,2,2,2l2.01,0c0.88,0,1.66-0.58,1.92-1.43l1.25-4.16l2.14-0.72c0.41-0.14,0.68-0.52,0.68-0.95V8.5c0-0.55-0.45-1-1-1 H19.83z M12,9H9C8.45,9,8,8.55,8,8v0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v0C13,8.55,12.55,9,12,9z M16,11c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    No lock in contracts
                                </div>
                                <div className={"description"}>
                                    There's no tricks, we're confident you'll
                                    like 23shout. Subscriptions are billed
                                    monthly, and if you're not happy we'll even
                                    pro-rata your time back to you.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill={"url(#brandGradient)"}
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                    <g>
                                        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" />
                                    </g>
                                </svg>
                                <div className={"title"}>Here to help</div>
                                <div className={"description"}>
                                    You're not left to figure it out, let's
                                    arrange a call anytime to help your team
                                    succeed with 23shout. No request is too
                                    large or small.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M10.15,5.15 L7.35,2.35c-0.2-0.2-0.51-0.2-0.71,0L3.85,5.15C3.54,5.46,3.76,6,4.21,6h1.81C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49 C8.08,7.2,8.03,6.63,8.02,6h1.78C10.24,6,10.46,5.46,10.15,5.15z M20.15,5.15l-2.79-2.79c-0.2-0.2-0.51-0.2-0.71,0l-2.79,2.79 C13.54,5.46,13.76,6,14.21,6h1.78c-0.1,3.68-1.28,4.75-2.54,5.88c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24 L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v4c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-4c0,0,0,0,0,0 c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37h1.8C20.24,6,20.46,5.46,20.15,5.15z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    Keep your data clean
                                </div>
                                <div className={"description"}>
                                    Setup call <strong>Outcomes</strong> with
                                    automated actions. Automatically schedule no
                                    answers to be called again in the future, or
                                    add numbers to Do Not Call lists.
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeaturesTeamSms
